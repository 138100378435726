import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { FormSchema } from '../features/targets/TargetNew/wizard/form.interface';

interface ExistingCandidateCreationState {
    caseId: string | null;
    formData: Partial<FormSchema>;
    cvImages: string[];
}

interface CandidateCreationState {
    candidateCreationData: ExistingCandidateCreationState;
    setTargetFormData: (data: ExistingCandidateCreationState) => void;
    clearCandidateData: () => void;
}

const initialState: ExistingCandidateCreationState = {
    caseId: null,
    formData: {},
    cvImages: [],
};

// Take note of JS cloning for deep-nested objects
export const useCandidateCreationStateStore = create<CandidateCreationState>()(
    devtools(
        persist(
            (set) => ({
                candidateCreationData: initialState,
                setTargetFormData: (update) =>
                    set(() => ({
                        candidateCreationData: {
                            ...update,
                        },
                    })),
                clearCandidateData: () =>
                    set(() => ({
                        candidateCreationData: initialState,
                    })),
            }),
            { name: 'candidateCreationStateStore' },
        ),
    ),
);
