import { DetailedHTMLProps, forwardRef, SelectHTMLAttributes } from 'react';
import classnames from 'classnames';
import { SelectOption } from '../../../features/targets/TargetNew/wizard/form.interface';

interface WizardFormSelectProps
    extends DetailedHTMLProps<
        SelectHTMLAttributes<HTMLSelectElement>,
        HTMLSelectElement
    > {
    label?: string;
    errorMsg?: string;
    options: SelectOption[];
}

export const WizardSelect = forwardRef<
    HTMLSelectElement,
    WizardFormSelectProps
>((props, ref) => {
    const {
        className,
        label,
        errorMsg,
        required,
        placeholder,
        children,
        options,
        value,
        ...rest
    } = props;
    return (
        <div className="w-full max-w-flow-text-base mx-auto relative">
            {label && (
                <label
                    className="block text-sm font-bold font-jost text-neutral-500"
                    htmlFor={rest.id}
                >
                    {label}
                    {required && <span className="text-error-1"> *</span>}
                </label>
            )}
            <select
                className={classnames(
                    className,
                    'bg-neutral-50 px-4 py-3.5 text-base focus:ring-0 border-neutral-400 focus:border-primary-4 hover:border-primary-4 block w-full rounded-md transition-all border-2',
                    rest.disabled && 'opacity-50',
                )}
                value={value}
                ref={ref}
                {...rest}
            >
                <option value="">-</option>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.labelTranslationKey}
                    </option>
                ))}
            </select>
            {errorMsg && <div className="text-error-1">{errorMsg}</div>}
            {placeholder && (
                <div className="text-neutral-500">{placeholder}</div>
            )}
            {children}
        </div>
    );
});
WizardSelect.displayName = 'WizardSelect';
