import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DynamicFormField } from './DynamicFormField';
import { DynamicFormProps, FormSchema } from './form.interface';
import { Button } from '_atoms';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '_molecules';

export const DynamicForm: React.FC<DynamicFormProps> = ({
    fields,
    onSubmit,
    submitButtonText = 'confirm',
    submitButtonClassName = '',
    defaultValues,
    parentKey,
    arrayPath,
    arrayIndex,
    onCancel,
}) => {
    const { t } = useTranslation();
    const [showWarning, setShowWarning] = useState(false);
    const {
        handleSubmit,
        control,
        reset,
        formState: { isDirty },
    } = useForm<Partial<FormSchema>>({
        defaultValues,
        criteriaMode: 'all',
        mode: 'onChange',
    });

    useEffect(() => {
        reset(defaultValues);
    }, [arrayIndex, defaultValues, reset]);

    const handleCancel = () => {
        if (isDirty) {
            setShowWarning(true);
        } else if (onCancel) {
            onCancel();
        }
    };

    const handleModalEvent = (event: 'confirm' | 'cancel') => {
        switch (event) {
            case 'confirm':
                setShowWarning(false);
                if (onCancel) {
                    onCancel();
                }
                break;
            case 'cancel':
                setShowWarning(false);
                break;
        }
    };

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col items-center justify-center h-full gap-4"
            >
                {fields.map((field) => {
                    const fullKey = parentKey
                        ? `${parentKey}.${field.key}`
                        : field.key;

                    return (
                        <DynamicFormField
                            key={fullKey}
                            field={{ ...field, key: fullKey }}
                            control={control}
                            arrayPath={arrayPath}
                            arrayIndex={arrayIndex}
                        />
                    );
                })}
                <div>
                    {onCancel && (
                        <Button
                            level="primaryGhost"
                            className="mx-6"
                            onClick={handleCancel}
                            type="button"
                        >
                            {t('cancel')}
                        </Button>
                    )}

                    <Button type="submit" className={submitButtonClassName}>
                        {t(submitButtonText)}
                    </Button>
                </div>
            </form>
            <ConfirmationModal
                body={t(
                    'You have unsaved changes. Are you sure you want to cancel?',
                )}
                cancelButtonText={t('deleteCase.cancelButton')}
                confirmButtonText={t('deleteCase.confirmButton')}
                enableConfirm={true}
                isOpen={showWarning}
                radioOptions={{
                    options: [],
                    handleSelect: () => {
                        // WTF
                        return;
                    },
                }}
                title={t('deleteCase.modalTitle')}
                handleButtonClick={handleModalEvent}
            />
        </>
    );
};
