import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Headline, LoadingSpinner, OverlayWithSpinner } from '_atoms';
import { ResultsProps } from '../Results';
import { useSuspicionsByCategory } from '_queries';
import { generatePath, useParams } from 'react-router-dom';
import { formatDateTime } from '_utils';
import { resultsRoutes } from '../../../routes';
import { TargetStatus } from '../../../services/dataService';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { OverviewBadge } from './OverviewBadge';
import { SearchStatistics } from './SearchStatistics';
import { ProfileInfo } from './ProfileInfo';
import { riskSections } from '../RiskAnalysisV2/RiskAnalysis';
import { SuspicionRisk } from '../RiskAnalysisV2/SuspicionRisk';
import { SuspicionFilters, useFilters } from '../RiskAnalysisV2/useFilters';
import { SuspicionRiskLevel } from '_enums';

export const OverviewV2: FC<ResultsProps> = ({
    targetData,
    targetImages,
    caseData,
    redFlags,
}) => {
    const { t } = useTranslation();

    const { redirectWithFilters } = useFilters();

    const { targetId } = useParams<{ targetId: string; caseId: string }>();

    const suspicionsByCategoryQuery = useSuspicionsByCategory(
        targetId,
        {},
        targetData?.status === TargetStatus.HasInitialProfile,
    );

    const hasSuspicions = Boolean(
        suspicionsByCategoryQuery.data?.high.length ||
            suspicionsByCategoryQuery.data?.medium.length ||
            suspicionsByCategoryQuery.data?.low.length,
    );

    const isCompleted =
        targetData?.status === TargetStatus.Completed ||
        targetData?.status === TargetStatus.CompletedWithIrregularities;

    const redirectToRiskAnalysis = (newFilters: SuspicionFilters): void => {
        const pathname = generatePath(resultsRoutes.riskAnalysis.path, {
            targetId,
            caseId: caseData?.id,
        });

        redirectWithFilters(pathname, newFilters);
    };

    const targetProfileData = targetData?.profile?.data;

    const profileData = useMemo(() => {
        if (!targetProfileData) {
            return undefined;
        }

        return {
            ...targetProfileData,
            occupation: targetProfileData?.occupation?.slice(0, 10),
        };
    }, [targetProfileData]);

    const isEmptyData = useMemo(() => {
        if (!isCompleted) {
            return false;
        }
        if (!suspicionsByCategoryQuery.data) {
            return false;
        }
        return !hasSuspicions;
    }, [isCompleted, suspicionsByCategoryQuery.data, hasSuspicions]);

    if (
        suspicionsByCategoryQuery.isLoading &&
        !suspicionsByCategoryQuery.isFetched
    ) {
        return (
            <div className="flex justify-center w-full z-0">
                <LoadingSpinner message={t('profileLoading')} />
            </div>
        );
    }

    const nonEmptyRiskLevels = riskSections.filter(
        (risk) => suspicionsByCategoryQuery.data?.[risk].length,
    );

    return (
        <DefaultPageLayout
            title={
                targetData
                    ? `${targetData?.firstname} ${targetData?.lastname}`
                    : t('overview')
            }
            subtitle={
                targetData
                    ? `${t('createdAt')} ${formatDateTime(
                          targetData.createdAt,
                      )}`
                    : undefined
            }
        >
            <div className="row">
                <div className="w-1/4 space-y-5 overflow-always-hidden">
                    <ProfileInfo
                        targetImages={targetImages}
                        profileData={profileData}
                    />
                </div>
                <div className="w-3/4 space-y-5">
                    <OverviewBadge
                        hasSuspicions={hasSuspicions}
                        isCompleted={isCompleted}
                    />
                    <OverlayWithSpinner
                        className="space-y-5 mb-8"
                        showOverlay={suspicionsByCategoryQuery.isFetching}
                    >
                        {isEmptyData && (
                            <div className="bg-white rounded p-8">
                                <div className="flex flex-col items-center justify-center gap-2">
                                    <img
                                        src="/magnifier.svg"
                                        className="w-60 mx-auto"
                                    ></img>
                                    <Headline Level="h4">
                                        {t('overviewAnalysisNoResult')}
                                    </Headline>
                                    <p className="mb-8 text-font-dark">
                                        {t('noSuspicionsDescription')}
                                    </p>
                                </div>
                            </div>
                        )}
                        {!isEmptyData && (
                            <div className="flex flex-col gap-7 mt-5">
                                {suspicionsByCategoryQuery.isSuccess &&
                                    hasSuspicions &&
                                    nonEmptyRiskLevels.map((risk) => (
                                        <SuspicionRisk
                                            key={risk}
                                            risk={risk}
                                            suspicions={
                                                suspicionsByCategoryQuery.data[
                                                    risk
                                                ]
                                            }
                                            setFilters={redirectToRiskAnalysis}
                                            filters={{}}
                                            redFlags={
                                                risk === SuspicionRiskLevel.High
                                                    ? redFlags
                                                    : undefined
                                            }
                                        />
                                    ))}
                            </div>
                        )}
                    </OverlayWithSpinner>
                    {isEmptyData && <SearchStatistics />}
                </div>
            </div>
        </DefaultPageLayout>
    );
};
