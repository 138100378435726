import { DateRange } from '../../../features/targets/TargetNew/wizard/form.interface';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WizardInput } from '../Input/WizardInput';
import classnames from 'classnames';
import { Button } from '_atoms';
import { WizardDateInput } from './WizardDatePicker';

const formatDateRange = (range: DateRange): string => {
    return range.start && range.end ? `${range.start} - ${range.end}` : '';
};

interface WizardDateRangeInputProps {
    value: DateRange;
    onChange: (value: DateRange) => void;
    onBlur: () => void;
    label?: string;
    errorMsg?: string;
    required?: boolean;
    disabled?: boolean;
    hint?: string;
}

export const WizardDateRangeInput = forwardRef<
    HTMLInputElement,
    WizardDateRangeInputProps
>((props, ref) => {
    const { t } = useTranslation();
    const {
        value,
        onChange,
        onBlur,
        label,
        errorMsg,
        required,
        disabled,
        hint,
    } = props;

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const popupRef = useRef<HTMLDivElement>(null);
    const [internalValue, setInternalValue] = useState<DateRange>({
        start: value.start ?? '',
        end: value.end ?? '',
    });

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                popupRef.current &&
                !popupRef.current.contains(event.target as Node)
            ) {
                setIsPopupOpen(false);
                onBlur();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onBlur]);

    const handleDateChange = (newDate: string, key: 'start' | 'end') => {
        setInternalValue((prev) => ({ ...prev, [key]: newDate }));
    };

    const handleSubmit = () => {
        onChange(internalValue);
        setIsPopupOpen(false);
    };

    return (
        <div className="w-full max-w-flow-text-base mx-auto relative">
            <WizardInput
                label={label}
                errorMsg={errorMsg}
                required={required}
                value={formatDateRange(value)}
                ref={ref}
                readOnly
                onClick={() => !disabled && setIsPopupOpen(true)}
                disabled={disabled}
                hint={hint ?? ''}
                className={classnames(
                    'w-full p-2 border rounded cursor-pointer',
                    { 'bg-gray-100': disabled },
                )}
            />
            {isPopupOpen && (
                <div
                    ref={popupRef}
                    className="absolute flex flex-wrap z-10 w-full bg-white border rounded shadow-lg p-4"
                >
                    <div className="w-1/2 pr-1">
                        <WizardDateInput
                            value={internalValue.start}
                            onChange={(newDate) =>
                                handleDateChange(newDate, 'start')
                            }
                            onBlur={onBlur}
                            label={t('Start Date')}
                        />
                    </div>
                    <div className="w-1/2 pl-1">
                        <WizardDateInput
                            value={internalValue.end}
                            onChange={(newDate) =>
                                handleDateChange(newDate, 'end')
                            }
                            onBlur={onBlur}
                            label={t('End Date')}
                        />
                    </div>
                    <div className="w-full grow mt-2 text-right">
                        <Button
                            level="darkGhost"
                            type="button"
                            onClick={handleSubmit}
                        >
                            {t('submit')}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
});

WizardDateRangeInput.displayName = 'WizardDateRangeInput';
