import { FC, HTMLAttributes, ReactNode } from 'react';
import classnames from 'classnames';

export interface PageLayoutProps extends HTMLAttributes<HTMLDivElement> {
    leftContent: ReactNode;
    rightContent: ReactNode;
    leftClassName?: string;
    rightClassName?: string;
    className?: string;
}

export const TwoColumnPageLayout: FC<PageLayoutProps> = (props) => {
    const {
        leftContent,
        rightContent,
        leftClassName,
        rightClassName,
        className,
        ...rest
    } = props;

    const halfClassNames =
        'container-fluid p-9 xl:max-w-half-max-content relative flex-basis-1/2 z-1 overflow-y-auto';

    return (
        <div
            className={classnames(
                'h-content-height overflow-hidden container-fluid p-0 relative flex z-1 before:content-[""] before:absolute before:top-0 before:left-0 before:h-full before:bg-contrast-2 before:z-0',
                className,
            )}
            {...rest}
        >
            <div className={classnames(halfClassNames, leftClassName)}>
                {leftContent}
            </div>
            <div className={classnames(halfClassNames, rightClassName)}>
                {rightContent}
            </div>
        </div>
    );
};
