import { Control, FieldValues, RegisterOptions } from 'react-hook-form';
import { ColorLevel, WeightLevel } from '_atoms';
import { DynamicFormValue, SocialMediaType, Title } from '@indicium/common';

export interface DateRange {
    start: string;
    end: string;
}

export interface Contact {
    email: string;
    phone: string;
    website: string;
}

export interface Job {
    jobTitle?: string;
    // TODO: should be date range
    jobDate?: DateRange;
    selfEmployed?: boolean;
    companyName: string;
    companyWebsite?: string;
    companyVatNumber?: string;
    companyCommercialRegisterNumber?: string;
    companyCountry?: string;
    companyCity?: string;
}

export interface Education {
    title?: string;
    type?: string;
    date?: DateRange;
    institutionName?: string;
    institutionLocation?: string;
}

export interface SocialMediaProfile {
    [SocialMediaType.facebook]?: string;
    [SocialMediaType.instagram]?: string;
    [SocialMediaType.linkedin]?: string;
    [SocialMediaType.twitter]?: string;
    [SocialMediaType.xing]?: string;
}

export type PersonalDetailsSchema = {
    title?: Title;
    firstName: string;
    lastName: string;
    middleName?: string;
    gender?: 'male' | 'female';
};

export type BirthInfoSchema = {
    dateOfBirth?: string;
    placeOfBirth?: string;
    countryOfBirth?: string;
};

export type FormSchema = {
    personalDetails: PersonalDetailsSchema;

    birthInfo: BirthInfoSchema;

    countryOfResidence?: string;
    contact: Contact;
    socialMediaProfiles: SocialMediaProfile;
    // nicknames: string[];
    nationalities: { nationality: string }[];
    jobs: Job[];
    education: Education[];

    // TODO: do we need theses?
    // bankAccounts?: {
    //     iban: string;
    //     bic: string;
    // }[];

    images: string[];
    relatedPersons: DynamicFormValue[];
    websites: DynamicFormValue[];
    organizations: DynamicFormValue[];
    topics: DynamicFormValue[];
    locations: DynamicFormValue[];
    nicknames: DynamicFormValue[];
};

export const formTypes = [
    'personalDetails',
    'birthInfo',
    'jobs',
    'socialMediaProfiles',
    'nationalities',
    'residenceInfo',
    'contactInfo',
    'educationInfo',
    // 'nickname',

    'images',
    'relatedPersons',
    'websites',
    'organizations',
    'topics',
    'locations',
    'nicknames',
] as const;

export type FormType = typeof formTypes[number];

export type FormSchemaArrayKeys = {
    [K in keyof FormSchema]: FormSchema[K] extends Array<any> ? K : never;
}[keyof FormSchema];

export type FormInputType =
    | 'text'
    | 'number'
    | 'email'
    | 'password'
    | 'url'
    | 'tel'
    | 'date'
    | 'dateRange'
    | 'time'
    | 'select'
    | 'textarea'
    | 'checkbox'
    | 'radio'
    | 'file'
    | 'arrayField'
    | 'nestedField';

export interface FormFieldBase {
    key: string;
    labelTranslationKey: string;
    type: FormInputType;
    defaultValue?: unknown;
    placeholder?: string;
    validators?: RegisterOptions;
    className?: string;
    labelClassName?: string;
    inputClassName?: string;
    disabled?: boolean;
    value?: any;

    fields?: FormFieldConfig[];
}

export interface DateRangeFieldConfig extends FormFieldBase {
    type: 'dateRange';
    start: FormFieldConfig;
    end: FormFieldConfig;
}

export interface NestedFieldConfig extends FormFieldBase {
    type: 'nestedField';
    fields: FormFieldConfig[];
}

export interface ArrayFieldConfig extends FormFieldBase {
    type: 'arrayField';
    fields: FormFieldConfig[];
}

export interface SelectOption {
    labelTranslationKey: string;
    value: string;
}

export interface SelectFieldConfig extends FormFieldBase {
    type: 'select';
    options: SelectOption[];
    addEmptyOption?: boolean;
    color?: ColorLevel;
    weight?: WeightLevel;
    dataTestId?: string;
    doNotTruncate?: boolean;
}

export type FormFieldConfig =
    | FormFieldBase
    | SelectFieldConfig
    | ArrayFieldConfig
    | DateRangeFieldConfig
    | NestedFieldConfig;

export interface DynamicFormFieldProps {
    field: FormFieldConfig;
    control?: Control;
    arrayPath?: string;
    arrayIndex?: number;
}

export interface DynamicFormProps {
    fields: FormFieldConfig[];
    onSubmit: (data: FieldValues) => void;
    onCancel?: () => void;
    submitButtonText?: string;
    submitButtonClassName?: string;
    defaultValues?: Partial<FormSchema>;
    parentKey?: string;
    arrayPath?: string;
    arrayIndex?: number;
}
