import { FC } from 'react';
import { FormSchema, FormType } from './form.interface';
import { SubmitHandler } from 'react-hook-form';
import { formConfigMap } from './form-configs';
import { DynamicForm } from './DynamicForm';

export const ShowForm: FC<{
    selectedFormType: FormType;
    onSubmit: SubmitHandler<Partial<FormSchema>>;
    onCancel: () => void;
    formValues: Partial<FormSchema>;
    editMode?: boolean;
    editIndex?: number;
}> = (props) => {
    const {
        selectedFormType,
        onSubmit,
        onCancel,
        formValues,
        editMode,
        editIndex,
    } = props;

    if (!selectedFormType || !formConfigMap[selectedFormType]) {
        return null;
    }

    const { formFields, parentKey, arrayPath } =
        formConfigMap[selectedFormType];

    let arrayIndex = undefined;
    if (arrayPath) {
        arrayIndex = editMode ? editIndex : formValues[arrayPath]?.length ?? 0;
    }

    return (
        <DynamicForm
            fields={formFields}
            onSubmit={onSubmit}
            onCancel={onCancel}
            defaultValues={formValues}
            parentKey={parentKey}
            arrayPath={arrayPath}
            arrayIndex={arrayIndex}
        />
    );
};
