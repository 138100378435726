import { DateRange as FormSchemaDateRange, FormSchema } from './form.interface';
import {
    ContactType,
    DateRange,
    DynamicFormValue,
    SocialMediaType,
    UserInputTarget,
} from '@indicium/common';
import { uniqueId } from 'lodash';
import { ProcessTarget } from '@indicium/common/src/types/Target/TargetTypes';
import moment from 'moment/moment';
import { toDateStr } from '@indicium/common/src/types/Date/DateStr';
import { generateDateRangeString } from '../../TargetForm/targetFormUtils';

const extractDateRangeObjForFormSchema = (
    dateRange: DateRange | string | undefined,
): FormSchemaDateRange => {
    const result: FormSchemaDateRange = {
        start: '',
        end: '',
    };
    if (typeof dateRange === 'object') {
        result.start = toDateStr(new Date(dateRange.start).toISOString());
        result.end = toDateStr(new Date(dateRange.end).toISOString());
    } else if (typeof dateRange === 'string' && dateRange) {
        result.start = toDateStr(new Date(dateRange).toISOString());
        result.end = toDateStr(new Date(dateRange).toISOString());
    }
    return result;
};

export const mapUserInputTargetToFormSchema = (
    input: ProcessTarget,
): FormSchema => {
    const formSchema: FormSchema = {
        personalDetails: {
            firstName: input.firstname,
            lastName: input.lastname,
            middleName: input.middlename,
            title: input.title,
            gender: input.gender,
        },
        birthInfo: {
            dateOfBirth: input.dateOfBirth ? input.dateOfBirth.start : '',
            placeOfBirth: input.placeOfBirth,
            countryOfBirth: input.countryOfBirth,
        },

        countryOfResidence: input.countryOfResidence,
        nationalities: (input.nationalities ?? [])
            ?.filter(Boolean)
            .map((item) => ({ nationality: item })),
        contact: {
            email: '',
            phone: '',
            website: '',
        },
        jobs: [],
        education: [],
        socialMediaProfiles: {},
        images: input.images ?? [],
        relatedPersons: (input?.relatedPersons ?? []).map((item) => ({
            id: item.id,
            value: item.value,
            status: item.status,
        })),
        websites: input.websites ?? [],
        organizations: input.organizations ?? [],
        topics: input.topics ?? [],
        locations: input.locations ?? [],
        //TODO: push input.nickname into nicknames if it exists
        nicknames: input.nicknames ?? [],
    };

    // Map contact
    if (input.contact) {
        input.contact.forEach((contact) => {
            switch (contact.type) {
                case ContactType.email:
                    formSchema.contact.email = contact.value;
                    break;
                case ContactType.phone:
                    formSchema.contact.phone = contact.value;
                    break;
                case ContactType.website:
                    formSchema.contact.website = contact.value;
                    break;
            }
        });
    }

    formSchema.jobs = (input.jobs ?? []).map((job) => ({
        jobTitle: job.title,
        jobDate: extractDateRangeObjForFormSchema(job.date),
        selfEmployed: job.selfEmployed,
        companyName: job.company?.name || '',
        companyWebsite: job.company?.website,
        companyVatNumber: job.company?.vatNumber,
        companyCommercialRegisterNumber: job.company?.commercialRegisterNumber,
        companyCity: job.company?.city,
    }));
    // Map education
    formSchema.education = (input.education ?? []).map((edu) => ({
        title: edu.title,
        type: edu.type,
        date: extractDateRangeObjForFormSchema(edu.date),
        institutionName: edu.institution?.name,
        institutionLocation: edu.institution?.location,
    }));

    // Map social media profiles
    (input.socialMediaProfiles ?? []).forEach((profile) => {
        if (Object.values(SocialMediaType).includes(profile.type)) {
            formSchema.socialMediaProfiles[profile.type] = profile.value;
        }
    });

    return formSchema;
};

type MappableField = keyof Pick<
    UserInputTarget,
    | 'relatedPersons'
    | 'websites'
    | 'organizations'
    | 'topics'
    | 'locations'
    | 'nicknames'
>;

const fieldsToMap: MappableField[] = [
    'relatedPersons',
    'websites',
    'organizations',
    'topics',
    'locations',
    'nicknames',
];

const mapArrayWithId = (array: any[]): DynamicFormValue<string>[] =>
    array.map((item) => ({
        id: uniqueId(),
        value: item.value,
        status: item.status,
    }));

const createDateRangeObjFromFormSchema = (
    dateRange: FormSchemaDateRange | undefined,
): string | undefined => {
    return dateRange?.start && dateRange?.end
        ? generateDateRangeString({
              start: toDateStr(
                  moment(dateRange.start, ['DD.MM.YYYY', 'YYYY']).format(
                      'YYYY-MM-DD',
                  ),
              ),
              end: toDateStr(
                  moment(dateRange.end, ['DD.MM.YYYY', 'YYYY']).format(
                      'YYYY-MM-DD',
                  ),
              ),
          })
        : undefined;
};

export const mapFormSchemaToUserInputTarget = (
    formData: Partial<FormSchema>,
): UserInputTarget => {
    const { personalDetails, birthInfo } = formData;

    const userInputTarget: UserInputTarget = {
        title: personalDetails?.title,
        gender: personalDetails?.gender,
        firstname: personalDetails?.firstName ?? '',
        lastname: personalDetails?.lastName ?? '',
        middlename: personalDetails?.middleName,

        dateOfBirth: birthInfo?.dateOfBirth
            ? moment(birthInfo?.dateOfBirth).format('DD.MM.YYYY')
            : undefined,
        placeOfBirth: birthInfo?.placeOfBirth,
        countryOfBirth: birthInfo?.countryOfBirth,

        countryOfResidence: formData.countryOfResidence,

        nationalities: (formData?.nationalities ?? [])
            .filter(Boolean)
            .map((item) => item.nationality),
        contact: [],
        jobs: [],
        education: [],
        socialMediaProfiles: [],
        images: formData.images,

        relatedPersons: formData.relatedPersons,
        websites: formData.websites,
        organizations: formData.organizations,
        topics: formData.topics,
        locations: formData.locations,
        nicknames: formData.nicknames,
    };

    fieldsToMap.forEach((field) => {
        userInputTarget[field] = mapArrayWithId(formData[field] ?? []);
    });

    // Map contact Data
    // TODO: DRY wins possible later?
    if (formData?.contact?.email) {
        userInputTarget.contact?.push({
            type: ContactType.email,
            value: formData?.contact.email,
        });
    }
    if (formData?.contact?.phone) {
        userInputTarget.contact?.push({
            type: ContactType.phone,
            value: formData?.contact.phone,
        });
    }
    if (formData?.contact?.website) {
        userInputTarget.contact?.push({
            type: ContactType.website,
            value: formData?.contact.website,
        });
    }

    // Map jobs
    userInputTarget.jobs = (formData?.jobs ?? []).map((job) => ({
        title: job.jobTitle,
        date: createDateRangeObjFromFormSchema(job?.jobDate),
        selfEmployed: job.selfEmployed,
        company: {
            name: job.companyName,
            website: job.companyWebsite,
            vatNumber: job.companyVatNumber,
            commercialRegisterNumber: job.companyCommercialRegisterNumber,
            country: job.companyCountry,
            city: job.companyCity,
        },
    }));

    // Map education
    userInputTarget.education = (formData?.education ?? []).map((edu) => {
        console.log({ edu });
        return {
            title: edu.title,
            type: edu.type,
            date: createDateRangeObjFromFormSchema(edu?.date),
            institution: {
                name: edu.institutionName,
                location: edu.institutionLocation,
            },
        };
    });

    // Map social media profiles
    userInputTarget.socialMediaProfiles = Object.entries(
        formData?.socialMediaProfiles ?? {},
    )
        .filter(([_key, value]) => value !== undefined && value !== '')
        .map(([key, value]) => ({
            type: key as SocialMediaType,
            value: value as string,
        }));

    return userInputTarget;
};
