import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

interface WizardDateInputProps {
    value: string;
    onChange: (value: string) => void;
    onBlur: () => void;
    label?: string;
    errorMsg?: string;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
}

export const WizardDateInput = forwardRef<
    HTMLInputElement,
    WizardDateInputProps
>((props, ref) => {
    const { t } = useTranslation();
    const { value, onChange, onBlur, label, errorMsg, required, disabled } =
        props;

    return (
        <div className="w-full max-w-flow-text-base mx-auto relative">
            {label && (
                <label className="block text-sm font-bold font-jost text-neutral-500 mb-2">
                    {label}
                    {required && <span className="text-error-1"> *</span>}
                </label>
            )}
            <input
                ref={ref}
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                className={classnames('w-full p-2 border rounded', {
                    'bg-gray-100': disabled,
                })}
                disabled={disabled}
            />
            <span className="text-neutral-500 block">
                {t('Full date or Year (DD.MM.YYYY or YYYY)')}
            </span>
            {errorMsg && <div className="text-error-1 mt-2">{errorMsg}</div>}
        </div>
    );
});

WizardDateInput.displayName = 'WizardDateInput';
