import React, { useCallback } from 'react';
import { DateRange, FormFieldConfig, FormSchema } from './form.interface';
import { useTranslation } from 'react-i18next';
import { Button } from '_atoms';
import classnames from 'classnames';
import { FiEdit } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';
import moment, { unitOfTime } from 'moment';

const formatDate = (dateString: string, key: keyof DateRange) => {
    const date = moment(dateString, moment.ISO_8601, true);
    if (!date.isValid()) {
        return '';
    }
    const timeUnit: unitOfTime.StartOf =
        dateString.length === 4 ? 'year' : 'day';

    return key === 'start'
        ? date.startOf(timeUnit).format('DD/MM/YYYY')
        : date.endOf(timeUnit).format('DD/MM/YYYY');
};

export interface DynamicFormFieldDetailsProps {
    data: Partial<Pick<FormSchema, keyof FormSchema>>;
    formConfig: FormFieldConfig[];
    onEditData?: () => void;
    onDeleteData?: () => void;
}

export const DynamicFormFieldDetails: React.FC<DynamicFormFieldDetailsProps> =
    React.memo(({ data, formConfig, onEditData, onDeleteData }) => {
        const { t } = useTranslation();

        const handleEdit = useCallback<
            React.MouseEventHandler<HTMLButtonElement>
        >(
            (event) => {
                event.preventDefault();
                event.stopPropagation();
                if (onEditData) {
                    onEditData();
                }
            },
            [onEditData],
        );

        const handleDelete = useCallback<
            React.MouseEventHandler<HTMLButtonElement>
        >(
            (event) => {
                event.preventDefault();
                event.stopPropagation();
                if (onDeleteData) {
                    onDeleteData();
                }
            },
            [onDeleteData],
        );

        const renderField = useCallback(
            (field: FormFieldConfig) => {
                if (!data) {
                    return null;
                }

                const value = data[field.key as keyof FormSchema];

                if (!value) {
                    return '-';
                }

                switch (field.type) {
                    // TODO validate with Zod or a similar library
                    case 'dateRange':
                        if (
                            typeof value === 'object' &&
                            'start' in value &&
                            'end' in value
                        ) {
                            const start =
                                value.start && typeof value.start === 'string'
                                    ? formatDate(value.start, 'start')
                                    : '';

                            const end =
                                value.end && typeof value.end === 'string'
                                    ? formatDate(value.end, 'end')
                                    : '';
                            return `${start} - ${end}`;
                        }
                        return '-';
                    case 'select':
                        return value as string;
                    case 'checkbox':
                        return value ? t('Yes') : t('No');
                    case 'arrayField':
                    case 'nestedField':
                        if (Array.isArray(value)) {
                            return value.map((item, index) => (
                                <div key={index} className="mb-2">
                                    {field.fields?.map((subField) => (
                                        <div
                                            key={subField.key}
                                            className="ml-4"
                                        >
                                            <strong>
                                                {t(
                                                    subField.labelTranslationKey,
                                                )}
                                                :
                                            </strong>{' '}
                                            {renderField({
                                                ...subField,
                                                key: subField.key as keyof typeof item,
                                            })}
                                        </div>
                                    ))}
                                </div>
                            ));
                        }
                        return '-';
                    default:
                        return String(value);
                }
            },
            [data, t],
        );

        return (
            <div className="hover:bg-primary-4/15 rounded-md">
                <div className="flex justify-end w-full">
                    <Button
                        level="custom"
                        type="button"
                        className={classnames(
                            'mr-2 text-primary-4 hover:text-primary-3',
                            !onEditData
                                ? 'cursor-not-allowed  opacity-50'
                                : 'cursor-pointer',
                        )}
                        icon={FiEdit}
                        disabled={!onEditData}
                        onClick={handleEdit}
                    >
                        Edit Section
                    </Button>
                    {onDeleteData && (
                        <Button
                            level="custom"
                            type="button"
                            className={classnames(
                                'text-red-700 hover:text-red-600',
                                !onDeleteData
                                    ? 'cursor-not-allowed opacity-50'
                                    : 'cursor-pointer',
                            )}
                            icon={MdDeleteOutline}
                            onClick={handleDelete}
                        >
                            Delete Section
                        </Button>
                    )}
                </div>
                <div className="p-4 grid grid-cols-2 gap-4">
                    {formConfig.map((field) => {
                        return (
                            <div key={field.key} className="col-span-1">
                                <strong className="block mb-1">
                                    {t(field.labelTranslationKey)}
                                </strong>
                                <span>{renderField(field)}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    });

DynamicFormFieldDetails.displayName = 'DynamicFormFieldDetails';
