import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, Redirect, useParams } from 'react-router-dom';
import { TwoColumnPageLayout } from '../../../components/Layout/TwoColumnPageLayout';
import { useCreateTargetMutation } from '_queries';
import { useTranslation } from 'react-i18next';
import { Button, Headline, Paragraph } from '_atoms';
import { SubmitHandler } from 'react-hook-form';
import { AutoComplete } from '../../../components/_atoms/AutoComplete/AutoComplete';
import { FormSchema, FormType } from './wizard/form.interface';
import { CVUploader } from '../../../components/_molecules/CVUploader';
import { routes } from '../../../routes';
import { DynamicForm } from './wizard/DynamicForm';
import { formTypeOptions, nameFormConfig } from './wizard/form-configs';
import { ShowForm } from './wizard/ShowForm';
import {
    mapFormSchemaToUserInputTarget,
    mapUserInputTargetToFormSchema,
} from './wizard/utils';
import { sanitizeInput } from '@indicium/common/src/utils';
import { ProcessTarget, UserInputTarget } from '@indicium/common';
import { TargetFormSchema } from '../../../schemas/targetFormSchema';
import { useCandidateCreationStateStore } from '../../../state/useStore';
import { CandidateDetails } from './wizard/CandidateDetails';
import { MdDeleteOutline } from 'react-icons/md';
import classnames from 'classnames';
import { PiReadCvLogo } from 'react-icons/pi';

export const TargetNewV2: FC = () => {
    const { t } = useTranslation();

    const { candidateCreationData, setTargetFormData, clearCandidateData } =
        useCandidateCreationStateStore();

    const [currentlyVisibleForm, setCurrentlyVisibleForm] =
        useState<FormType | null>(null);

    // TODO: in next iteration, associate the value with the caseID
    const [formData, setFormData] = useState<Partial<FormSchema>>(
        candidateCreationData.formData,
    );

    const [cvImages, setCvImages] = useState<string[]>(
        candidateCreationData.cvImages,
    );

    const [editMode, setEditMode] = useState<boolean>(false);
    const [editIndex, setEditIndex] = useState<number>(0);

    const { caseId } =
        useParams<{
            caseId: string;
        }>();

    const createTargetMutation = useCreateTargetMutation();

    useEffect(() => {
        if (createTargetMutation.isSuccess) {
            clearCandidateData();
        }
    }, [createTargetMutation.isSuccess, clearCandidateData]);

    if (createTargetMutation.isError) {
        //TODO: for debuging purpose, remove later
        console.error('error in createTarget: ', createTargetMutation.error);
    }

    const handleSubmit = useCallback(
        async (values: Partial<FormSchema>) => {
            const dataToSubmit = mapFormSchemaToUserInputTarget(values);
            try {
                const data = sanitizeInput<UserInputTarget>(
                    (await TargetFormSchema.validate(
                        dataToSubmit,
                    )) as UserInputTarget,
                );
                if (data) {
                    createTargetMutation.mutate({
                        caseId,
                        data,
                        cvImages,
                    });
                }
            } catch (err) {
                //TODO: for debuging purpose, remove later
                console.log(JSON.stringify(err));
            }
        },
        [createTargetMutation, caseId, cvImages],
    );

    const [_dropping, setDropping] = useState(false);

    // TODO: move to separate component
    const Intro: FC = useCallback(() => {
        return (
            <div className="flex flex-col items-center justify-center w-full flex-wrap h-full gap-4">
                <Headline Level="h3" color="dark">
                    {t('Create new target')}
                </Headline>
                <Paragraph color="dark">
                    {t(
                        'You can either upload a PDF (resume) here or enter the data manually in the left pane.',
                    )}
                </Paragraph>

                <div className="bg-gray-200 h-40 w-40 rounded-full relative text-center">
                    <PiReadCvLogo className="absolute inset-0 w-full h-full text-gray-400" />
                </div>

                <CVUploader
                    customView={{
                        openElement(open) {
                            return (
                                <Button
                                    level="secondary"
                                    onClick={open}
                                    className="w-52"
                                    type="reset"
                                >
                                    {t('uploadPdf')}
                                </Button>
                            );
                        },
                    }}
                    onUpload={(target, images) => {
                        /*
                         * TODO: investigate reason why dates may be present in props from the "__original" key
                         *  but are not in the actual target data. e.g "Job" date ranges... issue comes from nest-API
                         */
                        const formDataFromResume =
                            mapUserInputTargetToFormSchema(
                                (target ?? {}) as ProcessTarget,
                            );
                        setFormData(formDataFromResume);
                        setCvImages(images ?? []);
                        setTargetFormData({
                            caseId,
                            formData: formDataFromResume,
                            cvImages: images ?? [],
                        });
                    }}
                    onError={(error) => {
                        alert(
                            t('cvUploadFailed') +
                                '\n' +
                                (typeof error === 'object' &&
                                error instanceof Error
                                    ? error.message
                                    : String(error)),
                        );
                    }}
                />
            </div>
        );
    }, [t, caseId, setTargetFormData]);

    const editEntry = (key: FormType, index?: number) => {
        if (index !== undefined) {
            setEditIndex(index);
        }

        setEditMode(true);
        setCurrentlyVisibleForm(key);
    };

    const introDone =
        formData &&
        formData.personalDetails?.firstName &&
        formData.personalDetails?.lastName;

    const resetForm = useCallback(() => {
        setFormData({});
        clearCandidateData();
        setEditMode(false);
        setEditIndex(0);
        setCurrentlyVisibleForm(null);
    }, [clearCandidateData]);

    const onSubmit: SubmitHandler<Partial<FormSchema>> = useCallback(
        (data, _event) => {
            setFormData(data);
            setTargetFormData({
                caseId,
                formData: data,
                cvImages,
            });
            setEditMode(false);
            setEditIndex(0);
            setCurrentlyVisibleForm(null);
        },
        [caseId, cvImages, setTargetFormData],
    );

    // TODO: move to separate component
    const rightContent = useMemo(
        () => (
            <>
                {!introDone ? (
                    <Intro />
                ) : (
                    <div className="flex flex-col w-full h-full gap-4">
                        <div className="text-right w-full">
                            <Button
                                level="errorGhost"
                                type="button"
                                className={classnames(
                                    'text-red-700 hover:text-red-600 mx-2 cursor-pointer',
                                )}
                                icon={MdDeleteOutline}
                                onClick={resetForm}
                                aria-label={'delete'}
                            >
                                Reset Form
                            </Button>

                            <Button
                                className="justify-self-start align-middle"
                                level="primary"
                                disabled={createTargetMutation.isLoading}
                                onClick={() => {
                                    handleSubmit(formData);
                                }}
                                type="submit"
                            >
                                {/*TODO: add translation*/}
                                {t('Create Candidate Report')}
                            </Button>
                        </div>
                        <CandidateDetails
                            data={formData}
                            cvImages={cvImages}
                            onUpdate={onSubmit}
                            editHandler={editEntry}
                        />
                    </div>
                )}
            </>
        ),
        [
            introDone,
            Intro,
            resetForm,
            createTargetMutation.isLoading,
            t,
            formData,
            cvImages,
            onSubmit,
            handleSubmit,
        ],
    );

    const suggestedEntries: FormType[] = [
        'birthInfo',
        'contactInfo',
        'jobs',
        'socialMediaProfiles',
    ];

    // TODO: move to separate component
    const leftContent = (
        <>
            {!introDone ? (
                <DynamicForm
                    fields={nameFormConfig}
                    parentKey="personalDetails"
                    onSubmit={onSubmit}
                />
            ) : currentlyVisibleForm === null ? (
                <div className="flex flex-col items-center justify-center h-full gap-4">
                    <p className="max-w-flow-text-base">
                        While you have entered the minimum amount of data
                        required, we recommend adding these additional data or
                        going through the dropdown below for the entire list of
                        information you could add.
                    </p>
                    <div className="flex flex-row max-w-flow-text-base flex-wrap items-center justify-center mt-2 mb-2 gap-4">
                        {suggestedEntries.map((entry) => (
                            <Button
                                level="darkGhost"
                                key={entry}
                                onClick={() => setCurrentlyVisibleForm(entry)}
                                className="w-1/4 flex-1 h-20 capitalize"
                            >
                                {t(entry)}
                            </Button>
                        ))}
                    </div>

                    <div className="w-full max-w-flow-text-base">
                        <AutoComplete
                            options={formTypeOptions}
                            onChange={setCurrentlyVisibleForm}
                        />
                        <p className="px-2 text-sm font-bold font-jost text-yellow-500">
                            Add New detail about candidate from dropdown above
                            or click button below to create report
                        </p>
                    </div>
                </div>
            ) : (
                <ShowForm
                    formValues={formData}
                    selectedFormType={currentlyVisibleForm}
                    onSubmit={(values) => {
                        setEditMode(false);
                        setEditIndex(0);
                        setCurrentlyVisibleForm(null);
                        onSubmit(values);
                    }}
                    onCancel={() => {
                        setEditMode(false);
                        setEditIndex(0);
                        setCurrentlyVisibleForm(null);
                    }}
                    editMode={editMode}
                    editIndex={editIndex}
                />
            )}
        </>
    );

    if (createTargetMutation.isSuccess) {
        return (
            <Redirect
                to={generatePath(routes.targetShow.path, {
                    caseId,
                    targetId: createTargetMutation.data.id,
                })}
            />
        );
    }

    return (
        <TwoColumnPageLayout
            leftContent={leftContent}
            rightContent={rightContent}
            rightClassName="bg-neutral-300"
            onDragEnter={() => setDropping(true)}
            onDragLeave={() => setDropping(false)}
        />
    );
};
