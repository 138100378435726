import React, { useCallback } from 'react';
import { FormSchema, FormType } from './form.interface';
import { useTranslation } from 'react-i18next';
import { Card, Headline } from '_atoms';
import { formConfigMap } from './form-configs';
import { MdOutlinePerson } from 'react-icons/md';
import { DynamicFormFieldDetails } from './DynamicFormFieldDetails';
import { SubmitHandler } from 'react-hook-form';

export interface CandidateDetailsProps {
    data: Partial<FormSchema>;
    cvImages: string[];
    onUpdate: SubmitHandler<Partial<FormSchema>>;
    editHandler: (key: FormType, index?: number) => void;
}

// TODO: refactor bits of component logic to make things simpler
export const CandidateDetails: React.FC<CandidateDetailsProps> = ({
    data,
    cvImages = [],
    editHandler,
    onUpdate,
}) => {
    const { t } = useTranslation();

    const { personalDetails = {} } = data;

    const deleteHandler = useCallback(
        (key: FormType, index?: number) => {
            /*
             * Deep Clone form data for mutation...
             * If we run into issues with deletion down the line, we need to do the deep clone in a more robust way.
             * It will work fine right now
             * since we do not have date types and other types that do not play well with stringify/parse
             */
            const newData = JSON.parse(JSON.stringify(data));
            const config = formConfigMap[key];

            // Deletion logic for keys with an array-type
            if (config.arrayPath && index !== undefined) {
                const pathParts = config.arrayPath.split('.');
                let current = newData;
                for (let i = 0; i < pathParts.length - 1; i++) {
                    current = current[pathParts[i]];
                }
                const lastPart = pathParts[pathParts.length - 1];
                if (Array.isArray(current[lastPart])) {
                    current[lastPart].splice(index, 1);
                    if (current[lastPart].length === 0) {
                        delete current[lastPart];
                    }
                }
                // Deletion logic for keys with nested objects
            } else if (config.parentKey) {
                delete newData[config.parentKey];
                /*
                 * TODO: the below will be redundant when we refactor to make the form obj keys the source of truth
                 *  for form groupings
                 */
                // Deletion logic for everything else
            } else {
                config.formFields.forEach((field) => {
                    delete newData[field.key];
                });
            }
            onUpdate(newData);
        },
        [data, onUpdate],
    );

    const renderImage = () => {
        return (
            <div className="bg-gray-200 h-40 w-40 rounded-full relative text-center">
                {cvImages.length ? (
                    <img
                        src={
                            cvImages[0].includes('http')
                                ? cvImages[0]
                                : `data:image/jpeg;base64,${cvImages[0]}`
                        }
                        alt="Profile"
                        className="rounded-full absolute inset-0 w-full h-full object-cover"
                    />
                ) : (
                    <MdOutlinePerson className="absolute inset-0 w-full h-full text-gray-400" />
                )}
            </div>
        );
    };

    const renderSection = (
        formType: FormType,
        config: typeof formConfigMap[FormType],
    ) => {
        let sectionData: any;

        if (config.parentKey) {
            sectionData = data[config.parentKey as keyof FormSchema];
        } else if (config.arrayPath) {
            sectionData = data[config.arrayPath as keyof FormSchema];
        } else {
            // For configs without parentKey or arrayPath, extract only the relevant fields
            sectionData = config.formFields.reduce((acc, field) => {
                const value = data[field.key as keyof FormSchema];
                if (value !== undefined) {
                    acc[field.key] = value;
                }
                return acc;
            }, {} as Record<string, any>);
        }

        const isEmptyObject = (obj: any): boolean => {
            if (obj && typeof obj !== 'object') {
                return true;
            }
            return Object.values(obj).every(
                (value) =>
                    !value ||
                    (Array.isArray(value) && value.length === 0) ||
                    (typeof value === 'object' && isEmptyObject(value)),
            );
        };

        if (
            !sectionData ||
            (Array.isArray(sectionData) && sectionData.length === 0) ||
            (!Array.isArray(sectionData) && isEmptyObject(sectionData))
        ) {
            return null;
        }

        return (
            <section key={formType} className="my-2 w-full p-2">
                <Headline Level="h4" className="p-4 capitalize">
                    {t(formType)}
                </Headline>
                <hr className="border-b-2 my-4 border-primary-1" />
                {Array.isArray(sectionData) ? (
                    sectionData.map((item, index) => (
                        <div
                            key={index}
                            className="mb-6 pb-6 border-b border-gray-200 last:border-b-0"
                        >
                            <DynamicFormFieldDetails
                                data={item}
                                formConfig={config.formFields}
                                onEditData={() => editHandler(formType, index)}
                                onDeleteData={() =>
                                    deleteHandler(formType, index)
                                }
                            />
                        </div>
                    ))
                ) : (
                    <DynamicFormFieldDetails
                        data={sectionData}
                        formConfig={config.formFields}
                        onEditData={() => editHandler(formType)}
                        onDeleteData={() => deleteHandler(formType)}
                    />
                )}
            </section>
        );
    };

    return (
        <>
            <Card className="w-full pt-4 overflow-y-auto">
                <div className="flex w-full items-center justify-center">
                    <div className="w-1/3 p-2 flex justify-center items-center">
                        {renderImage()}
                    </div>
                    <div className="w-2/3 p-2">
                        <DynamicFormFieldDetails
                            data={personalDetails}
                            formConfig={
                                formConfigMap.personalDetails.formFields
                            }
                            onEditData={() => editHandler('personalDetails')}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-full items-center justify-center">
                    {Object.entries(formConfigMap).map(([key, config]) => {
                        if (key !== 'personalDetails') {
                            return renderSection(key as FormType, config);
                        }
                        return null;
                    })}
                </div>
            </Card>
        </>
    );
};
