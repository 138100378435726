import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { FormType } from '../../../features/targets/TargetNew/wizard/form.interface';
import { WizardInput } from '../Input/WizardInput';
import classnames from 'classnames';

export type AutocompleteOption = {
    label: string;
    value: NonNullable<FormType>;
};

type AutocompleteProps = {
    options: Array<AutocompleteOption>;
    onChange: (value: NonNullable<FormType> | null) => void;
    placeholder?: string;
    label?: string;
};

export const AutoComplete = forwardRef<HTMLInputElement, AutocompleteProps>(
    (
        {
            options,
            onChange,
            placeholder = 'Select an option...',
            label = 'Add new detail',
        },
        ref,
    ) => {
        const [selectedOption, setSelectedOption] =
            useState<AutocompleteOption | null>(null);
        const [input, setInput] = useState('');
        const [suggestions, setSuggestions] =
            useState<Array<AutocompleteOption>>(options);
        const [showSuggestions, setShowSuggestions] = useState(false);
        const wrapperRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            function handleClickOutside(event: MouseEvent) {
                if (
                    wrapperRef.current &&
                    !wrapperRef.current.contains(event.target as Node)
                ) {
                    setShowSuggestions(false);
                    setSuggestions(options);
                    setInput('');
                }
            }

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [options]);

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setInput(value);
            if (value.length > 0) {
                const filteredSuggestions = options.filter((item) =>
                    item.label.toLowerCase().includes(value.toLowerCase()),
                );
                setSuggestions(filteredSuggestions);
            } else {
                setSuggestions(options);
            }
        };

        const searchInputRef = useRef<HTMLInputElement>(null);

        const handleSuggestionClick = (option: AutocompleteOption | null) => {
            setSelectedOption(option);
            setShowSuggestions(false);
            setInput('');
            onChange(option?.value ?? null);
        };

        const handleSelectedClick = () => {
            setShowSuggestions(true);
            setTimeout(() => {
                searchInputRef.current?.focus();
            }, 0);
        };

        return (
            <div
                ref={wrapperRef}
                className="flex flex-col items-center justify-center w-full relative"
            >
                <WizardInput
                    type="text"
                    value={selectedOption ? selectedOption.label : ''}
                    onClick={handleSelectedClick}
                    placeholder={placeholder}
                    label={label}
                    ref={ref}
                    readOnly
                >
                    {selectedOption && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleSuggestionClick(null);
                            }}
                            className="absolute right-2 top-1/2 transform text-gray-400 hover:text-gray-600"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    )}
                    {showSuggestions && (
                        <div className="absolute flex flex-col top-full bg-neutral-50 text-base border-neutral-400 w-full max-h-72 rounded-md transition-all border-2 max-w-flow-text-base z-10">
                            <span className="p-2 flex-1">
                                <WizardInput
                                    type="text"
                                    value={input}
                                    onChange={handleInputChange}
                                    placeholder="Search..."
                                    ref={searchInputRef}
                                />
                            </span>
                            {suggestions.length > 0 ? (
                                <ul className="flex-1 overflow-y-auto bg-neutral-50 text-base border-neutral-400 w-full rounded-md transition-all max-w-flow-text-base divide-y">
                                    {suggestions.map((suggestion) => (
                                        <li
                                            key={suggestion.value}
                                            onClick={() =>
                                                handleSuggestionClick(
                                                    suggestion,
                                                )
                                            }
                                            className={classnames(
                                                'px-4 py-3.5 cursor-pointer hover:bg-primary-4/15 capitalize',
                                                suggestion.value ===
                                                    selectedOption?.value &&
                                                    'bg-primary-4',
                                            )}
                                        >
                                            {suggestion.label}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="px-4 py-3.5 text-neutral-500">
                                    No results found
                                </p>
                            )}
                        </div>
                    )}
                </WizardInput>
            </div>
        );
    },
);

AutoComplete.displayName = 'AutoComplete';
