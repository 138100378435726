import {
    FormFieldConfig,
    FormSchema,
    FormSchemaArrayKeys,
    FormType,
    formTypes,
} from './form.interface';
import {
    ContactType,
    DynamicFormValueStatus,
    SocialMediaType,
    Title,
} from '@indicium/common';
import {
    getCountrySelectionOptions,
    getNationalitySelectionOptions,
} from '../countryCodeOptions';
import i18n, { t } from 'i18next';
import { AutocompleteOption } from '../../../../components/_atoms/AutoComplete/AutoComplete';
import { europeanCharactersRegex } from '@indicium/common/src/utils';
import moment from 'moment';

const nationalitySelectionOptions = getNationalitySelectionOptions(
    i18n.language,
);

const countrySelectionOptions = getCountrySelectionOptions(i18n.language);

const datePattern =
    /^(\d{4}|(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.\d{4})$/;
// TODO: remove when very certain the validation bits for date are right everywhere
// const dateRangePattern =
//     /^(\d{4}|(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.\d{4}) ?- ?(\d{4}|(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.\d{4})$/;

const validateDate = (value: string): boolean | string => {
    if (!value) {
        return true;
    }
    const currentDate = moment();
    const inputDate = moment(value, ['DD.MM.YYYY', 'YYYY'], true);
    return (
        (inputDate.isValid() && inputDate.isSameOrBefore(currentDate)) ||
        'schemaValidation.invalidDate'
    );
};

const validateDateRange = (start: string, end: string): boolean | string => {
    if (!start || !end) {
        return true;
    }
    const currentDate = moment();
    const startDate = moment(start, ['DD.MM.YYYY', 'YYYY'], true);
    const endDate = moment(end, ['DD.MM.YYYY', 'YYYY'], true);
    return (
        (startDate.isValid() &&
            endDate.isValid() &&
            startDate.isSameOrBefore(endDate) &&
            endDate.isSameOrBefore(currentDate)) ||
        'schemaValidation.invalidDateRange'
    );
};

export const formTypeOptions: AutocompleteOption[] = formTypes
    .filter((type) => type !== 'personalDetails')
    .map((type) => ({
        value: type,
        label: type,
    }));

export const nameFormConfig: FormFieldConfig[] = [
    {
        key: 'title',
        labelTranslationKey: 'title',
        type: 'select',
        options: Object.values(Title).map((title) => ({
            id: title,
            value: title,
            labelTranslationKey: title,
        })),
    },
    {
        key: 'gender',
        labelTranslationKey: 'gender',
        type: 'select',
        options: [
            { value: 'male', labelTranslationKey: 'Male' },
            { value: 'female', labelTranslationKey: 'Female' },
        ],
    },
    {
        key: 'firstName',
        labelTranslationKey: 'firstname',
        type: 'text',
        validators: {
            required: 'schemaValidation.required',
            pattern: {
                value: europeanCharactersRegex,
                message: 'schemaValidation.invalidCharacter',
            },
        },
    },
    {
        key: 'lastName',
        labelTranslationKey: 'lastname',
        type: 'text',
        validators: {
            required: 'schemaValidation.required',
            pattern: {
                value: europeanCharactersRegex,
                message: 'schemaValidation.invalidCharacter',
            },
        },
    },
    {
        key: 'middleName',
        labelTranslationKey: 'middlename',
        type: 'text',
        validators: {
            pattern: {
                value: europeanCharactersRegex,
                message: 'schemaValidation.invalidCharacter',
            },
        },
    },
];

export const birthInformationFormConfig: FormFieldConfig[] = [
    {
        key: 'dateOfBirth',
        labelTranslationKey: 'dateOfBirth',
        type: 'date',
        validators: {
            validate: (value: any) => {
                if (!value) {
                    return true;
                }
                const currentDate = moment();
                const inputDate = moment(value, ['DD.MM.YYYY', 'YYYY'], true);
                return (
                    (inputDate.isValid() &&
                        inputDate.isSameOrBefore(currentDate)) ||
                    'schemaValidation.invalidDate'
                );
            },
        },
    },
    {
        key: 'placeOfBirth',
        labelTranslationKey: 'placeOfBirth',
        type: 'text',
    },
    {
        key: 'countryOfBirth',
        labelTranslationKey: 'countryOfBirth',
        type: 'select',
        options: countrySelectionOptions.map(({ code, name }) => {
            return {
                id: code,
                value: code,
                labelTranslationKey: name,
            };
        }),
    },
];

export const residenceFormConfig: FormFieldConfig[] = [
    {
        key: 'countryOfResidence',
        labelTranslationKey: 'countryOfResidence',
        type: 'select',
        options: countrySelectionOptions.map(({ code, name }) => {
            return {
                id: code,
                value: code,
                labelTranslationKey: name,
            };
        }),
    },
];

export const nationalityFormConfig: FormFieldConfig[] = [
    {
        key: 'nationality',
        labelTranslationKey: 'nationality',
        type: 'select',
        options: nationalitySelectionOptions.map(({ code, name }) => {
            return {
                id: code,
                value: code,
                labelTranslationKey: name,
            };
        }),
    },
];

const MIN_LENGTH = 5;

export const jobsFormConfig: FormFieldConfig[] = [
    {
        key: 'companyName',
        labelTranslationKey: 'jobCompanyName',
        type: 'text',
        validators: {
            required: 'schemaValidation.required',
        },
    },
    {
        key: 'jobTitle',
        labelTranslationKey: 'jobTitle',
        type: 'text',
    },
    {
        key: 'jobDate',
        labelTranslationKey: 'jobDate',
        type: 'dateRange',
        start: {
            key: 'start',
            labelTranslationKey: 'educationTypeStart',
            type: 'date',
            value: '',
            validators: {
                pattern: {
                    value: datePattern,
                    message: 'schemaValidation.invalidDate',
                },
                validate: validateDate,
            },
        },
        end: {
            key: 'end',
            labelTranslationKey: 'educationTypeEnd',
            type: 'date',
            value: '',
            validators: {
                pattern: {
                    value: datePattern,
                    message: 'schemaValidation.invalidDate',
                },
                validate: validateDate,
            },
        },
        validators: {
            validate: (value: { start: string; end: string }) => {
                if (value.start && value.end) {
                    return validateDateRange(value.start, value.end);
                }
                return true;
            },
        },
    },
    {
        key: 'selfEmployed',
        labelTranslationKey: 'selfEmployed',
        type: 'checkbox',
    },
    {
        key: 'companyWebsite',
        labelTranslationKey: 'jobCompanyWebsite',
        type: 'url',
        validators: {
            pattern: {
                value: /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/,
                message: 'schemaValidation.invalidUrl',
            },
        },
    },
    {
        key: 'companyVatNumber',
        labelTranslationKey: 'jobCompanyVatNumber',
        type: 'text',
        validators: {
            minLength: {
                value: MIN_LENGTH,
                message: t('schemaValidation.minimumCharacters', {
                    minLength: MIN_LENGTH,
                }),
            },
        },
    },
    {
        key: 'companyCommercialRegisterNumber',
        labelTranslationKey: 'jobCompanyCommercialRegisterNumber',
        type: 'text',
        validators: {
            minLength: {
                value: MIN_LENGTH,
                message: t('schemaValidation.minimumCharacters', {
                    minLength: MIN_LENGTH,
                }),
            },
        },
    },
    {
        key: 'companyCountry',
        labelTranslationKey: 'jobCompanyLocation',
        type: 'select',
        options: countrySelectionOptions.map(({ code, name }) => {
            return {
                id: code,
                value: code,
                labelTranslationKey: name,
            };
        }),
    },
    {
        key: 'companyCity',
        labelTranslationKey: 'jobCompanyCity',
        type: 'text',
    },
];

export const nicknameFormConfig: FormFieldConfig[] = [
    {
        key: 'nickname',
        labelTranslationKey: 'Nickname',
        type: 'text',
        validators: {
            pattern: {
                value: europeanCharactersRegex,
                message: 'schemaValidation.invalidCharacter',
            },
        },
    },
];

export const socialMediaFormConfig: FormFieldConfig[] = Object.values(
    SocialMediaType,
).map((socialMediaType) => ({
    key: socialMediaType,
    labelTranslationKey: socialMediaType,
    type: 'text',
    placeholder: `${socialMediaType}Placeholder`,
}));

export const contactFormConfig: FormFieldConfig[] = Object.values(
    ContactType,
).map((type) => ({
    key: type,
    labelTranslationKey: type,
    type: type === 'email' ? 'email' : 'text',
    placeholder: `${type}Placeholder`,
    validators:
        type === 'email'
            ? {
                  pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'schemaValidation.invalidEmail',
                  },
              }
            : type === 'phone'
            ? {
                  pattern: {
                      value: /^\+[0-9]{5,16}$/,
                      message: 'schemaValidation.invalidPhone',
                  },
              }
            : {},
}));

export const educationFormConfig: FormFieldConfig[] = [
    {
        key: 'title',
        labelTranslationKey: 'educationTitle',
        type: 'text',
    },
    {
        key: 'type',
        labelTranslationKey: 'educationType',
        type: 'text',
    },
    {
        key: 'date',
        labelTranslationKey: 'educationDate',
        type: 'dateRange',
        start: {
            key: 'start',
            labelTranslationKey: 'educationTypeStart',
            type: 'date',
            value: '',
            validators: {
                pattern: {
                    value: datePattern,
                    message: 'schemaValidation.invalidDate',
                },
                validate: validateDate,
            },
        },
        end: {
            key: 'end',
            labelTranslationKey: 'educationTypeEnd',
            type: 'date',
            value: '',
            validators: {
                pattern: {
                    value: datePattern,
                    message: 'schemaValidation.invalidDate',
                },
                validate: validateDate,
            },
        },
        validators: {
            validate: (value: { start: string; end: string }) => {
                if (value.start && value.end) {
                    return validateDateRange(value.start, value.end);
                }
                return true;
            },
        },
    },
    {
        key: 'institutionName',
        labelTranslationKey: 'educationInstitutionName',
        type: 'text',
    },
    {
        key: 'institutionLocation',
        labelTranslationKey: 'educationInstitutionLocation',
        type: 'text',
    },
];

// TODO: do we need these
// export const bankAccountFormConfig: FormFieldConfig[] = [
//     {
//         key: 'iban',
//         label: 'IBAN',
//         type: 'text',
//         validators: {
//             required: 'schemaValidation.required',
//         },
//     },
//     {
//         key: 'bic',
//         label: 'BIC',
//         type: 'text',
//         validators: {
//             required: 'schemaValidation.required',
//         },
//     },
// ];

export const imagesFormConfig: FormFieldConfig[] = [
    {
        key: 'image',
        labelTranslationKey: 'Image URL',
        type: 'text',
    },
];

// Dynamic Form Values (for relatedPersons, websites, organizations, topics, locations, nicknames)
function createDynamicFormValueConfig(): FormFieldConfig[] {
    return [
        {
            key: 'status',
            labelTranslationKey: 'Status',
            type: 'select',
            options: [
                {
                    value: DynamicFormValueStatus.Confirmed,
                    labelTranslationKey: 'dynamicForm.buttons.include',
                },
                {
                    value: DynamicFormValueStatus.Ignored,
                    labelTranslationKey: 'dynamicForm.buttons.exclude',
                },
            ],
        },
        {
            key: 'value',
            labelTranslationKey: 'Value',
            type: 'text',
        },
    ];
}

export const relatedPersonsFormConfig = createDynamicFormValueConfig();
export const websitesFormConfig = createDynamicFormValueConfig();
export const organizationsFormConfig = createDynamicFormValueConfig();
export const topicsFormConfig = createDynamicFormValueConfig();
export const locationsFormConfig = createDynamicFormValueConfig();
export const nicknamesFormConfig = createDynamicFormValueConfig();

// The order of this is the same order data will appear in the form list and details section
// TODO: path becomes redundant if we simply use the field keys, that way, it can be implied... refactor this later
export const formConfigMap: Record<
    FormType,
    {
        formFields: FormFieldConfig[];
        parentKey?: keyof FormSchema;
        arrayPath?: FormSchemaArrayKeys;
    }
> = {
    personalDetails: {
        formFields: nameFormConfig,
        parentKey: 'personalDetails',
    },
    birthInfo: {
        formFields: birthInformationFormConfig,
        parentKey: 'birthInfo',
    },
    jobs: {
        formFields: jobsFormConfig,
        arrayPath: 'jobs',
    },
    residenceInfo: {
        formFields: residenceFormConfig,
    },
    nationalities: {
        formFields: nationalityFormConfig,
        arrayPath: 'nationalities',
    },
    contactInfo: {
        formFields: contactFormConfig,
        parentKey: 'contact',
    },
    educationInfo: {
        formFields: educationFormConfig,
        arrayPath: 'education',
    },

    socialMediaProfiles: {
        formFields: socialMediaFormConfig,
        parentKey: 'socialMediaProfiles',
    },
    images: {
        formFields: imagesFormConfig,
        arrayPath: 'images',
    },
    relatedPersons: {
        formFields: relatedPersonsFormConfig,
        arrayPath: 'relatedPersons',
    },
    websites: {
        formFields: websitesFormConfig,
        arrayPath: 'websites',
    },
    organizations: {
        formFields: organizationsFormConfig,
        arrayPath: 'organizations',
    },
    topics: {
        formFields: topicsFormConfig,
        arrayPath: 'topics',
    },
    locations: {
        formFields: locationsFormConfig,
        arrayPath: 'locations',
    },
    nicknames: {
        formFields: nicknamesFormConfig,
        arrayPath: 'nicknames',
    },
};
